import React, {Component} from "react";


class About extends Component {
    render() {
        return (
            <>
                <main>
                    <h2>Who are we?</h2>
                    <p>
                        That feels like an existential question, don't you
                        think?
                    </p>
                </main>
            </>
        );
    }
}

export default About;
